<template>
  <div> 
    <v-row class="d-flex justify-space-around py-5">
      <v-card 
      width="85%"
      outlined
      elevation="4"
      >
        <v-card-title>
          {{ $t('license_page.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col xl="3" lg="3" md="4" sm="6">
              {{ $t('license_page.client') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="licenseInfo.CLIENT" />
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="3" lg="3" md="4" sm="6">
              {{ $t('license_page.number_of_universes') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="licenseInfo.MAX_UNI_COUNT" />
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="3" lg="3" md="4" sm="6">
              {{ $t('license_page.expire_date') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="expiredDate" />
            </v-col>
          </v-row>    
        </v-card-text>
        <v-card-actions class="justify-end pa-4">
          <v-btn
            color="primary--text"
            :disabled="!isAdministrator"
            @click="uploadLicenseFiledDialog = true"
          >{{ $t('license_page.upload_license_button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog persistent v-model="uploadLicenseFiledDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('license_page.upload_license_card.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValid">
            <v-file-input
              accept=".lic"
              small-chips
              show-size
              v-model="file"
            ></v-file-input>
            <p v-if="errors.length">
              <b>{{ $t('license_page.upload_license_card.error_message') }}:</b>
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </p>
          </v-form>
            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary--text" @click="close">
            {{ $t('license_page.upload_license_card.cancel') }}
          </v-btn>
          <v-btn :disabled="!isValid" color="primary--text" @click="uploadLicenseFile">
            {{ $t('license_page.upload_license_card.upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/api"

export default {
  data: () => ({
    uploadLicenseFiledDialog: false,
    isValid: false,
    file: null,
    errors: {},
  }),
  computed: {
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    licenseInfo() {
      return this.$store.state.license.licenseInfo
    },
    expiredDate(){
      return this.$store.getters['license/getExpiredDate'].format('LL')
    },
  },
  methods: {
    close () {
      this.uploadLicenseFiledDialog = false
      this.$nextTick(() => {
        this.file = null
        this.errors = {}
      })
    },
    uploadLicenseFile() {
      let formData = new FormData()

      formData.append('file', this.file)

      Api.uploadLicenseFile(formData)
      .then(
        () => {
          this.close()
        }
      )
      .catch(error => {
        this.errors = error.response.data
      })
    },
  },
  mounted () {
    this.$store.dispatch('license/getLicenseInfo')
  },
}
</script>

<style>

</style>